<div
    (click)="onClick()" 
    [ngClass]="{
        'rounded-xl border border-[#30386A] bg-[#0C133F]': style=='standard' || style=='close',
        'hover:bg-[#1F50A0] sm:rounded-xl sm:border sm:border-[#30386A] sm:bg-[#0C133F]': style=='back-mobile'
    }" 
    class="w-11 h-11 flex justify-center items-center cursor-pointer">
    <mat-icon *ngIf="style=='standard'" class="size-6 text-white rotate-180"
        [svgIcon]="'chevron_right'"></mat-icon>
    <mat-icon *ngIf="style=='back-mobile'" class="size-6 text-white"
        [svgIcon]="'arrow_back'"></mat-icon>
    <mat-icon *ngIf="style=='close'" class="size-6 text-white"
        [svgIcon]="'close'"></mat-icon>
</div>